<template>
<span>

    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">Gerenciar Empresa</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="primary" dark class="mb-1" @click="atualizarDados">
                <v-icon dark class="mdi mdi-sync"></v-icon>Atualizar
            </v-btn>
            <v-btn color="success" dark class="mb-1 ml-2" :to="{name: 'company.add'}">
                <v-icon class="mdi mdi-plus"></v-icon>Adicionar Nova Empresa
            </v-btn>
        </template>
    </v-toolbar>

    <v-card>
        <v-card-title>
            <v-text-field v-model="search" append-icon="search" label="Localizar" single-line hide-details></v-text-field>
        </v-card-title>

        <v-data-table :headers="headers" :items="loadCompanies" :search="search" :rowsPerPageItems="rowsPerPageItems" hide-default-footer>
            <template v-slot:[`item.comp_logo`]="{ item }">
                <td width="15%" v-if="item.comp_logo"><img :src="`${base_url}upload/company/${item.comp_logo}?${uuid}`" class="avatar"></td>
                <td width="15%" v-if="!item.comp_logo"><img :src="`${base_url}upload/logo.png`" class="avatar"></td>
            </template>
            <template v-slot:[`item.language.lang_flag`]="{ item }">
                <td><img v-if="item.language.lang_flag" :src="`${base_url}upload/language/${item.language.lang_flag}`" class="avatar"></td>
            </template>

            <template v-slot:[`item.comp_status`]="{ item }">
                <td>
                    <v-switch color="lime" v-model="item.comp_status" @change="onChangeStatusEventHandler(item.comp_id, $event)" class="my-switch"></v-switch>
                </td>
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
                <v-tooltip top color="blue white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="blue lighten-1" @click.prevent="editCompany(item)" v-on="on">
                            <v-icon dark>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>

                <v-tooltip top color="red white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="red lighten-1" @click.prevent="deleteCompany(item)" v-on="on">
                            <v-icon dark class="mdi mdi-delete"></v-icon>
                        </v-btn>
                    </template>
                    <span>Excluir</span>
                </v-tooltip>

                <v-tooltip top color="lime black--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="lime lighten-1" @click.prevent="detailCompany(item)" v-on="on">
                            <v-icon dark class="mdi mdi-reorder-horizontal"></v-icon>
                        </v-btn>
                    </template>
                    <span>Detalhes</span>
                </v-tooltip>
            </template>

            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">
                    Voce procurou por "{{ search }}" nenhum item localizado.
                </v-alert>
            </template>
        </v-data-table>

    </v-card>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../config/configs'
import Pagination from '../partials/PaginationComponent'
import {
    uuid
} from "vue-uuid";

export default {
    created() {
        this.$store.dispatch('loadCompanies')
    },
    computed: {
        loadCompanies() {
            if (this.$store.state.companies.items.data == null) {
                return []
            }

            return this.$store.state.companies.items.data
        }
    },
    name: 'CompanyComponent',
    data() {
        return {
            search: '',
            rowsPerPageItems: [10, 20, 30, {
                text: '$vuetify.dataIterator.rowsPerPageAll',
                10: -1
            }],
            headers: [{
                    text: 'logo',
                    align: 'left',
                    sortable: false,
                    value: 'comp_logo'
                },
                {
                    text: 'Idioma',
                    align: 'left',
                    sortable: false,
                    value: 'language.lang_flag'
                },
                {
                    sortable: false,
                    text: 'Fantasia',
                    value: 'comp_fantasia'
                },
                {
                    sortable: false,
                    text: 'Domínio',
                    value: 'comp_dominio'
                },
                {
                    sortable: false,
                    text: 'Status',
                    value: 'comp_status'
                },
                {
                    text: 'Ações',
                    align: 'center',
                    sortable: false,
                    value: 'acoes'
                }
            ],
            company: [],
            base_url: URL_BASE,
            uuid: uuid.v1()
        }
    },
    methods: {
        atualizarDados() {
            this.$store.dispatch('loadCompanies')
        },
        editCompany(item) {
            this.$router.push({
                name: 'company.edit',
                params: {
                    comp_id: item.comp_id
                }
            })
        },
        detailCompany(item) {
            this.$router.push({
                name: 'company.detail',
                params: {
                    comp_id: item.comp_id
                }
            })
        },
        deleteCompany(item) {
            this.$swal({
                title: 'Você tem certeza?',
                text: 'Você não poderá reverter isso',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#D32F2F',
                cancelButtonColor: '#1976D2',
                confirmButtonText: 'Sim, Exclua!'
            }).then((result) => {
                if (result.value) {
                    this.$store.dispatch('destroyCompany', item.comp_id)
                        .then(response => {
                            if (response) {
                                this.$swal({
                                    position: 'center',
                                    icon: 'success',
                                    title: 'Sucesso',
                                    text: 'Dados excluídos com sucesso',
                                    showConfirmButton: true,
                                    timer: 6000
                                })
                                this.atualizarDados()
                            } else {
                                this.$swal({
                                    position: 'center',
                                    icon: 'error',
                                    title: 'Opssss!',
                                    text: 'Erro ao excluir dados',
                                    showConfirmButton: true,
                                    timer: 6000
                                })
                            }
                        })
                        .catch(error => {
                            this.$swal({
                                position: 'center',
                                icon: 'error',
                                title: 'Opss! Algo errado aconteceu!',
                                text: error,
                                showConfirmButton: true,
                                timer: 6000
                            })
                        })
                }
            })
        },
        onChangeStatusEventHandler(comp_id, value) {
            this.$store.dispatch('updateCompanyStatus', {
                    comp_id: comp_id,
                    comp_status: value
                })
                .then(() => {
                    this.$swal({
                        position: 'center',
                        icon: 'success',
                        title: 'Sucesso',
                        text: 'Status do Status atualizado com sucesso',
                        showConfirmButton: true,
                        timer: 6000
                    })
                })
                .catch(error => {
                    this.$swal({
                        position: 'center',
                        icon: 'warning',
                        title: "Atenção! Não foi possível atualizar o status",
                        text: error,
                        showConfirmButton: true,
                        timer: 6000
                    }).then(() => {
                        this.atualizarDados()
                    })
                })
        }
    },
    components: {
        Pagination: Pagination
    }
}
</script>

<style scoped>
.avatar {
    height: 40px;
    margin-top: 0.4rem;
}
</style>
